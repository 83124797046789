import "./index.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { AuthProvider } from "react-oidc-context";
import { App } from "App";
import { userManager } from "userManager";
import "logging/oidcTelemetryLogger"

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthProvider userManager={userManager} >
      <App />
    </AuthProvider>
  </React.StrictMode>
);
