import { createContext, useState } from "react";
import PropTypes from "prop-types";

export const UserContext = createContext({

    userMustChangePinCode: false,
    setUserMustChangePinCode: (userMustChangePinCode) =>
        (this.userMustChangePinCode = userMustChangePinCode),

    busyFormItems: [],
    setBusyFormItems: (busyFormItems) => (this.busyFormItems = busyFormItems),
});

export const UserContextProvider = ({ children }) => {
    const [userMustChangePinCode, setUserMustChangePinCode] = useState(false);
    const [busyFormItems, setBusyFormItems] = useState([]);

    const removeBusyFormItem = (oid) => {
        busyFormItems.includes(oid) &&
            setBusyFormItems(busyFormItems.filter((val) => val !== oid));
    };
    const addBusyFormItem = (oid) => {
        !busyFormItems.includes(oid) &&
            setBusyFormItems([...busyFormItems, oid]);
    };

    const userContextValue = {
        userMustChangePinCode,
        setUserMustChangePinCode,
        busyFormItems,
        setBusyFormItems,
        addBusyFormItem,
        removeBusyFormItem,
    };

    return (
        <UserContext.Provider value={userContextValue}>
            {children}
        </UserContext.Provider>
    );
};

UserContextProvider.propTypes = {
    children: PropTypes.any,
};
