import { wherebyService } from "services/whereby";
import useSWR from "swr";
import { useAuth } from "react-oidc-context";

export const useFetchRoom = ({
  refreshInterval = ACTIVE_ROOM_POLLING,
  onSuccess,
  onError,
} = {}) => {
  const { isAuthenticated } = useAuth();
  let { data: room, error } = useSWR(
    isAuthenticated ? "/api/whereby" : null,
    wherebyService.getRoom,
    {
      refreshInterval: refreshInterval,
      ...(!!onSuccess && { onSuccess: onSuccess }),
      ...(!!onError && { onError: onError }),
    }
  );

  // eslint-disable-next-line eqeqeq
  room = room && room.status && room.status == 204 ? null : room;
  const isLoading = !error && !room;

  return { room, isLoading, error };
};

export const ACTIVE_ROOM_POLLING = 60000; // Polling interval in milliseconds
