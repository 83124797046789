import { getCookie, eraseCookie } from "helpers/cookies";
import { userManager } from "../userManager";

async function signoutRedirect() {
  const user = await userManager.getUser();
  return userManager.signoutRedirect({ id_token_hint: user && user.id_token });
}

const logout = async () => {
  eraseCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME);
  await signoutRedirect();
};

async function getLocalToken() {
  const user = await userManager.getUser();
  if (user) {
    return user.access_token;
  }

  // we already change the way to store access token from cookie to local storage
  // but worker generate pdf still use this cookie to generate so it's kind of fallback token for worker
  return getCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME);
}

const hasTokenInCookie = () =>
  getCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME) != null;

export const authenticationService = {
  logout,
  getLocalToken,
  hasTokenInCookie,
};
