import { useAuth } from "react-oidc-context";
import { i18n } from "localization/i18n";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { setCookie } from "helpers/cookies";
import {
  BROADCAST_MESSAGE,
  broadcastChannelManager,
} from "broadcastChannel/broadcastChannelManager";

export const SigninCallback = () => {
  const { user, isLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (user) {
      i18n.changeLanguage(user.profile.lang);

      if (user.profile.must_change_pin) {
        setCookie(process.env.REACT_APP_CHANGE_PIN_COOKIE_NAME, true);
        broadcastChannelManager.sendMessage(BROADCAST_MESSAGE.CHANGE_PIN);
        navigate("/change-pin");
        return;
      }
    }

    navigate("/");
  }, [isLoading, user, navigate]);

  return null;
};
