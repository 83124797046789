import { useAuth } from "react-oidc-context";
import { useEffect } from "react";
import { useAsyncError } from "hooks/useAsyncError";
import { BROADCAST_MESSAGE, broadcastChannelManager } from "broadcastChannel/broadcastChannelManager";

/**
 * This component is used exclusively for the reference token login flow (when account is shared using email or SMS).
 * In this flow, there is no return URL, so the Subject IdP will redirect to "/login" which will render this component.
 * This component will remove the current user and trigger a redirect to the Subject IdP,
 * which will immediately redirect back to "/signin-oidc" since the user is already logged in.
 */

export function ReferenceTokenSigninCallback() {
  const { removeUser, clearStaleState, signinRedirect } = useAuth();
  const throwError = useAsyncError();

  useEffect(() => {
    async function loginRedirect() {
      try {
        // Remove current user in case multiple users are using the same device
        await removeUser();
        await clearStaleState();

        // Notify other tabs that previous user is removed
        // This is necessary to prevent the inactivity tracker from triggering a logout due to using the previous users token
        broadcastChannelManager.sendMessage(BROADCAST_MESSAGE.LOGOUT);

        // Redirect to identity provider
        await signinRedirect();
      } catch (error) {
        throwError(error);
      }
    }

    loginRedirect();
  }, [clearStaleState, removeUser, signinRedirect, throwError]);

  return null;
}
