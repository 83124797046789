import { eraseCookie } from "helpers/cookies";
import { appInsightsTelemetry } from "logging/applicationInsightsService";
import { useEffect } from "react";
import { useAuth } from "react-oidc-context";

export const Signout = () => {
  const { signoutRedirect, removeUser } = useAuth();

  useEffect(() => {
    const signOutAsync = async () => {
      try {
        eraseCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME);
        await signoutRedirect();
      } catch (error) {
        appInsightsTelemetry.trackException(error);
        await removeUser();
      }
    };

    signOutAsync();
  }, [signoutRedirect, removeUser]);

  return null;
};
