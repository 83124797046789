import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { eraseCookie } from "helpers/cookies";
import { broadcastChannelManager, BROADCAST_MESSAGE } from "broadcastChannel/broadcastChannelManager";
import { useAsyncError } from "hooks/useAsyncError";
import { appInsightsTelemetry } from "logging/applicationInsightsService";

export function SignoutCallback() {
  const { signinRedirect } = useAuth();
  const throwError = useAsyncError();

  useEffect(() => {
    async function cleanupAsync() {
      try {
        broadcastChannelManager.sendMessage(BROADCAST_MESSAGE.LOGOUT);
      } catch (error) {
        appInsightsTelemetry.trackException(error);
      }

      try {
        eraseCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME);
        signinRedirect();
      } catch (error) {
        throwError(error);
      }
    }

    cleanupAsync();
  }, [signinRedirect, throwError]);

  return null;
}
