import "components/viedocui/index";
import "./App.css";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import {
  LoadingContext,
  LoadingContextProvider,
  actionTypes,
} from "context/LoadingContext";
import { UserContextProvider } from "context/UserContext";
import { VideoContextProvider } from "context/VideoContext";
import "./localization/i18n";
import { useLoadingOverlayDelay } from "hooks/useLoadingOverlayDelay";
import { ErrorBoundary } from "components/Base/ErrorBoundary/ErrorBoundary";
import { LoadingOverlay } from "components/Base/LoadingOverlay/LoadingOverlay";
import { ScrollToTop } from "components/ScrollToTop/ScrollToTop";
import {
  ShowLoadingContext,
  ShowLoadingContextProvider,
} from "context/ShowLoadingContext";
import { ApplicationRoutes } from "components/ApplicationRoutes/ApplicationRoutes";
import { InactivityTracker } from "components/InactivityTracker/InactivityTracker";
import { broadcastChannelManager } from "broadcastChannel/broadcastChannelManager";

function Main() {
  const { i18n, ready: translationsReady } = useTranslation(undefined, {
    useSuspense: false,
  });
  const { isLoading, dispatch } = useContext(LoadingContext);
  const { showLoading, setShowLoading } = useContext(ShowLoadingContext);
  const showLoadingOverlay = useLoadingOverlayDelay(isLoading);

  document.body.dir = i18n.dir();
  document.documentElement.lang = i18n.language;

  useEffect(() => {
    broadcastChannelManager.createChannel();

    return () => {
      broadcastChannelManager.closeChannel();
    };
  }, []);

  useEffect(() => {
    setShowLoading(showLoadingOverlay);
  }, [setShowLoading, showLoadingOverlay]);

  useEffect(() => {
    const LOADING_KEY = "translations";
    const type = translationsReady
      ? actionTypes.REMOVE_LOADING_ITEM
      : actionTypes.ADD_LOADING_ITEM;

    dispatch({
      type,
      key: LOADING_KEY,
    });
  }, [dispatch, translationsReady]);

  return (
    <ErrorBoundary>
      <InactivityTracker />
      <LoadingOverlay show={showLoading} />
      {translationsReady && <ApplicationRoutes />}
    </ErrorBoundary>
  );
}

export function App() {
  return (
    <ErrorBoundary>
        <BrowserRouter>
            <LoadingContextProvider>
                <ShowLoadingContextProvider>
                    <VideoContextProvider>
                        <UserContextProvider>
                            <ScrollToTop />
                            <Main />
                        </UserContextProvider>
                    </VideoContextProvider>
                </ShowLoadingContextProvider>
            </LoadingContextProvider>
        </BrowserRouter>
    </ErrorBoundary>
  );
}
