import { appInsightsTelemetry } from "logging/applicationInsightsService";
import { authenticationService } from "./authentication";

const getApiEndpoint = () => {
  if (process.env.REACT_APP_API_BASE_URL !== "") {
    // If the app was built with this variable set, we use it. Otherwise we base the API endpoint on our endpoint
    // using replace rules
    return process.env.REACT_APP_API_BASE_URL;
  } else if (window.location.hostname === "dev-test1me.viedoc.net") {
    // QUICKFIX: The original test environment doesn't follow any naming standard.
    return "https://v4me-dev2.viedoc.net";
  } else {
    const re = new RegExp(process.env.REACT_APP_API_BASE_URL_FIND);
    const apiHostName = window.location.hostname.replace(
      re,
      process.env.REACT_APP_API_BASE_URL_REPLACE
    );
    return window.location.protocol + "//" + apiHostName;
  }
};

const apiEndpoint = getApiEndpoint();

const STATUS_CODES = {
  ABORTED: 299, // There is no standard code for aborted
};

const ContentTypes = {
  JSON: "application/json",
  FORMDATA: "multipart/form-data",
};

const errorHandlers = {
  401: () => {
    // Redirect to signout page to utilize react-oidc-context for signing out.
    window.location.replace("/signout");
  },
};

const handleError = async (error) => {
  const errorHandler = errorHandlers[error.status];

  if (errorHandler) {
    errorHandler(error);
  }

  return Promise.reject(error);
};

const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response);
  }

  if (response.status === 204) {
    return Promise.resolve(response);
  }

  return response.json();
};

const getAuthHeader = async () => {
  const token = await authenticationService.getLocalToken();

  return {
    Authorization: `Bearer ${token}`,
  };
};

const tryJsonParse = (str) => {
  try {
    return JSON.parse(str);
  } catch (_) {
    return str;
  }
};

const getHeaders = async (contentType) => {
  const authHeader = await getAuthHeader();
  // Do NOT set Content-Type for form data since this will prevent
  // browsers from setting boundary expression
  if (contentType === ContentTypes.FORMDATA) {
    return authHeader;
  }

  return {
    "Content-Type": contentType,
    ...authHeader,
  };
};

const baseRequest = async ({ url, method, body, contentType }) => {
  validateUrl(url);

  const headers = await getHeaders(contentType ?? ContentTypes.JSON);

  return fetch(`${apiEndpoint}${url}`, {
    method,
    body:
      contentType === ContentTypes.FORMDATA
        ? body
        : body
        ? JSON.stringify(body)
        : null,
    headers,
  })
    .then(handleResponse)
    .catch(handleError);
};

const validateUrl = (url) => {
  if (url[0] !== "/") {
    throw Error("URL should start with '/'");
  }
};

const get = async (url) => {
  return baseRequest({ url, method: "GET" });
};

const getFile = async (url) => {
  validateUrl(url);

  const headers = await getHeaders();

  return fetch(`${apiEndpoint}${url}`, {
    method: "GET",
    headers,
  })
    .then((response) => response.blob())
    .catch(handleError);
};

const post = async (url, body, contentType) => {
  return baseRequest({ url, method: "POST", body, contentType });
};

const postWithoutTokenRefresh = async (url, body, contentType) => {
  return baseRequest({
    url,
    method: "POST",
    body,
    contentType,
  });
};

// Since fetch() does not support progress event on POST,
// use XMLHttpRequest instead
const postWithProgress = async (
  url,
  formData,
  progressHandler,
  abortCallback
) => {
  return new Promise(async function (resolve, reject) {
    const xhr = new XMLHttpRequest();
    let abortPolling = null;

    const clearAbortPolling = () => {
      if (abortPolling) {
        clearInterval(abortPolling);
        abortPolling = null;
      }
    };

    // Poll initiator for request abort
    if (abortCallback) {
      abortPolling = setInterval(() => {
        if (abortCallback()) {
          clearAbortPolling();
          xhr.abort();
        }
      }, 200);
    }

    xhr.open("POST", `${apiEndpoint}${url}`);

    // Transfer complete
    xhr.onload = function () {
      clearAbortPolling();
      const result = xhr.response ? tryJsonParse(xhr.response) : null;
      if (this.status >= 200 && this.status < 300) {
        resolve(result);
      } else {
        const message =
          typeof result === "string" ? { message: result } : result;
        reject({
          status: this.status,
          statusText: xhr.statusText,
          ...message,
        });
      }
    };

    // Transfer failed
    xhr.onerror = function () {
      clearAbortPolling();
      reject({
        status: this.status,
        statusText: xhr.statusText,
      });
    };

    // Transfer progress update
    xhr.upload.onprogress = function (event) {
      progressHandler(event);
    };

    // Transfer canceled
    xhr.onabort = function () {
      clearAbortPolling();
      reject({
        status: STATUS_CODES.ABORTED,
        statusText: xhr.statusText,
      });
    };

    let headers = {};
    try {
      headers = await getAuthHeader();
    } catch (error) {
      appInsightsTelemetry.trackException(error);
      reject(error);
    }

    Object.keys(headers).forEach((key) => {
      xhr.setRequestHeader(key, headers[key]);
    });

    xhr.send(formData);
  });
};

const put = async (url, body, contentType) => {
  return baseRequest({ url, method: "PUT", body, contentType });
};

export const http = {
  get,
  getFile,
  post,
  postWithProgress,
  postWithoutTokenRefresh,
  put,
  ContentTypes,
  STATUS_CODES,
  apiEndpoint,
};
